import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractControl } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';

import { toLabel } from '@app/ui/pipes/label.pipe';

export function RequiredValidatorMessage(_error: string, field: FormlyFieldConfig) {
  return `Please fill in ${toLabel(field.props?.label as string).toLowerCase()}`;
}

export function BackendValidatorMessage(error: string) {
  return error;
}

export const errorsFormTexts = {
  required: 'Please fill in all the required fields',
  birthday:
    'Please check the birthdays of all passengers. Alternatively, If you need to modify the order details, please reach out to your travel expert for assistance',
  card: 'Please check the card details.',
  optionSubmitError:
    'We are sorry, but we can’t book this flight at the moment. Please reach out to your travel expert for assistance.',
};

export const CCNumberValidator = (control: AbstractControl) => {
  return !CreditCardValidators.validateCCNumber(control);
};
